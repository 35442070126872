<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('admin.filters.title') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label for="category">{{ $t('admin.table.fields.category') }}</label>
          <treeselect
            id="category"
            :value="categoryFilter"
            :multiple="false"
            :options="categoryOptions"
            :normalizer="normalizer"
            class="w-100"
            @input="(value) => $emit('update:categoryFilter', value)"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label for="status">{{ $t('admin.table.fields.status') }}</label>
          <v-select
            id="status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import StatusMixin from '@/mixins/StatusMixin'
import Treeselect from '@riophae/vue-treeselect'
import CategoryMixin from '@/mixins/CategoryMixin'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    Treeselect,
  },
  mixins: [StatusMixin, CategoryMixin],
  props: {
    categoryFilter: {
      type: [Number, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      categoryOptions: [],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/admin/categories', {
      params: {
        type: 'post',
        status: 'enabled',
      },
    })
      .then(response => {
        this.categoryOptions = response.data
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
